import {Typography} from "@mui/material";
import React, {useContext} from 'react';
import {Stack, Box} from '@mui/material';
import PageCenter from "../Atoms/PageCenter";
import Swoosh from "../Atoms/Swoosh";
import {documentStore} from "../documentStore";
import PageHeaderLogos from "./PageHeaderLogos";

const SmallHeader = ({eventTitle, eventSubtitle, headingLogo, logoWidth}) => {
  const {docState} = useContext(documentStore);
  const smallLogoWidth = `${logoWidth * 0.70}px`;
  return (
    <Box sx={{
      backgroundColor: docState.present.kiteTheme.headerBackground || 'primary.main',
      color: '#fff',
      padding: `0 0 0 0`,
    }}>
      <PageCenter>
        <Stack sx={{
            width:'100%',
            alignItems: 'center',
            textAlign: 'center',
            paddingTop:'24px'
          }}>
          {!docState.present.kiteTheme.noHeaderLogos && <Box sx={{display:'flex'}}>
          <Box
            component="img"
            src={headingLogo}
            sx={{
              height: 'auto',
              width: {sm: smallLogoWidth, md: smallLogoWidth, lg: `${logoWidth}px`, xs: smallLogoWidth}
            }}
            alt="Logo Image"
          />
          </Box>}
          {eventTitle && <Typography variant="div" component="div"
                      sx={{
                        color: "primary.contrastText",
                        fontSize: "36px",
                        margin: "24px 0 36px",
                        textAlign: 'center'
                      }}
          >
            {eventTitle}
          </Typography>}
          {eventSubtitle && <Typography variant="div" gutterBottom component="div"
                      sx={{
                        color: "primary.contrastText",
                        fontSize: "19px",
                        margin: "-12px 0 36px",
                        textAlign: 'center'
                      }}
          >
            {eventSubtitle}
          </Typography>}
        </Stack>
      </PageCenter>
    </Box>
  );
}


const PageHeader = ({eventTitle, eventSubtitle, headingLogo, centerLogo, logoWidth, centerLogoWidth, smallHeader}) => {
  const {docState} = useContext(documentStore);
  const smallCenterWidth = `${centerLogoWidth * 0.70}px`;
  const headerBackgroundSettings = {};
  if (docState.present.kiteTheme.headerBackground) {
    headerBackgroundSettings.background = docState.present.kiteTheme.headerBackground;
  } else {
    headerBackgroundSettings.backgroundColor = 'primary.main';
  }
  return (
    <header>
      {smallHeader && <SmallHeader eventTitle={eventTitle} eventSubtitle={eventSubtitle} headingLogo={headingLogo} logoWidth={logoWidth} />}
      {!smallHeader && <Box sx={{
        ...headerBackgroundSettings,
        color: '#fff',
        padding: `0 0 ${docState.present.kiteTheme.noSwoosh ?
          docState.present.kiteTheme.headerBP || '130px' : '230px'} 0`,
        position: 'relative',
        '& .swoosh': {
          position: 'absolute',
          bottom: '0',
          height: '350px',
          width: '100%',
          left: '0px',
          overflow:'hidden'
        }
      }}>
      <PageCenter style={{zIndex:1}}>
        {<PageHeaderLogos url={headingLogo} logoWidth={logoWidth} />}
        <Stack sx={{
          width:'100%',
          alignItems: 'center',
          textAlign: 'center',
          paddingTop:'35px'
        }}>

          {centerLogo &&
            <Box
              component="img"
              src={centerLogo}
              alt={'Brand'}
              sx={{
                marginBottom: {xs: "41px", sm: "41px", md: "40px", lg: "50px"},
                width: {sm: smallCenterWidth, md: smallCenterWidth, lg: `${centerLogoWidth}px`, xs: smallCenterWidth}
              }}
            />
          }

          {eventTitle && <Typography variant="div" component="div"
                      sx={{
                        color: "primary.contrastText",
                        fontSize: {xs: "40px", sm: "40px", md: "50px", lg: "73px"},
                        marginBottom: "30px",
                        textAlign: 'center'
                      }}
          >
            {eventTitle}
          </Typography>}

          {eventSubtitle && <Typography variant="div" gutterBottom component="div"
                      sx={{
                        color: "primary.contrastText",
                        fontSize: "19px",
                        marginBottom: "-6px",
                        marginTop: "6px",
                        textAlign: 'center'
                      }}
          >
            {eventSubtitle}
          </Typography>}
        </Stack>
      </PageCenter>
      {
        !docState.present.kiteTheme.noSwoosh && <Swoosh background={docState.present.kiteTheme.background.default}/>
      }
      </Box>}
    </header>
  );
}

export const PageHeaderProps = [
  {key: 'eventTitle', type: 'string', default: 'Welcome to Rose Castle', label: 'Event Title'},
  {key: 'eventSubtitle', type: 'string', default: 'Tell us how we can make your stay exceptional?', label: 'Event Subtitle'},
  { key: 'headingLogo',
    type: 'string',
    default: 'https://www.rosecastle.com/hubfs/Rose-Castle/Home-Page/Logo_Rose.svg',
    image: true,
    label: 'Heading Logo'},
  {key: 'logoWidth', type: 'number', default: '310', label: 'Heading Logo Width'},
  {key: 'centerLogo',
    type: 'string',
    default: 'https://www.rosecastle.com/hubfs/Landing%20Page%202021/Header_flower.svg',
    image: true,
    label: 'Central Image'},
  {key: 'centerLogoWidth', type: 'number', default: '164', label: 'Center Image Width'},
  {key: 'smallHeader', type: 'bool', default: false, label: 'Enable Small Header'},
]

PageHeader.defaultProps = PageHeaderProps.reduce(
  (acc, cur) => ({...acc, [cur.key]: cur.default})
  , {});

export default PageHeader;
